<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    :max-width="570"
    persistent
    content-class="cardBg dialogBuyNumber pa-15 pa-sm-20"
    transition="scroll-y-transition"
    overlay-color="overlayColor"
  >
    <v-tabs-items v-model="currentTab" touchless>
      <v-tab-item :value="tabs.name">
        <DialogCardAccessApiTokenName
          :show="show"
          @goToConfirmStep="goToConfirmStep"
          @closeDialog="closeDialog"
        />
      </v-tab-item>

      <v-tab-item :value="tabs.confirm">
        <DialogCardConfirm
          :show="show"
          :loading="loadingAction"
          @sendConfirmData="sendConfirmData"
          @closeDialog="closeDialog"
        />
      </v-tab-item>

      <v-tab-item :value="tabs.token">
        <DialogCardApiToken
          :show="show"
          :token="token"
          @closeDialog="closeDialog"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import DialogCardAccessApiTokenName from '@/components/cards/DialogCardAccessApiTokenName'
import DialogCardConfirm from '@/components/cards/DialogCardConfirm'
import DialogCardApiToken from '@/components/cards/DialogCardApiToken'

import { createApiToken } from '@/api/api-tokens-routes'

export default {
  name: 'DialogCreateApiToken',
  inheritAttrs: false,
  components: { DialogCardAccessApiTokenName, DialogCardConfirm, DialogCardApiToken },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loadingAction: false,
    tabs: {
      name: 'name',
      confirm: 'confirm',
      token: 'token',
    },
    currentTab: 'name',
    formDataName: null,
    token: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    nameTab() {
      this.currentTab = this.tabs.name
    },
    goToConfirmStep(name) {
      this.formDataName = name
      this.currentTab = this.tabs.confirm
    },
    async sendConfirmData(confirmFormData) {
      this.loadingAction = true

      const formData = Object.assign( { name: this.formDataName }, confirmFormData)

      const { success, payload, message } = await createApiToken(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.token = payload.accessToken
        this.currentTab = this.tabs.token
        this.$emit('createSuccess')
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    closeDialog() {
      this.show = false
    },
    clearForm() {
      setTimeout(() => {
        this.currentTab = this.tabs.name
        this.formDataName = null
        this.token = null
      }, 300)
    },
  },
  watch: {
    show: {
      handler(show) {
        if (!show) {
          this.clearForm()
        }
      }
    }
  }
}
</script>