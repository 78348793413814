<template>
  <v-card class="cardBg" outlined>
    <v-form ref="form">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column align-start justify-center pr-0 pr-sm-11 pb-16"
          cols="12"
        >
          <span class="font-large-title text-uppercase grayLighten3--text">API Access token created</span>
        </v-col>

        <v-col
          class="d-flex flex-column justify-center pr-11 pb-4 pb-sm-10"
          cols="12"
        >
          <div class="d-flex align-center">
            <v-icon class="pr-5" size="34" color="yellow">$warningIcon</v-icon>
            <div class="font-normal nameField--text">This is the only time you will see this token. Please, copy it and store in a secure location</div>
          </div>
        </v-col>
        <v-col cols="12" class="mt-7 mt-sm-0 pb-8 pb-sm-10">
          <FormTextarea
            v-model="token"
            :rows="23"
            readonly
          />
        </v-col>

        <v-col
          class="d-flex pr-11"
          cols="12"
        >
          <span class="font-normal nameField--text pr-3 align-center">I have copied the token</span>

          <PrimaryCheckbox
            v-model="isCopied"
          />
        </v-col>
      </v-row>

      <v-card-actions class="justify-end pr-0 pt-0 mt-16">
        <ActionButton class="mr-5 mr-sm-10" @click="copyToken">Copy Token</ActionButton>

        <CancelButton @click="closeDialog">Close</CancelButton>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormTextarea from '@/components/inputs/FormTextarea'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

import { copyInStash } from '@/helper/app-helper'

export default {
  name: 'DialogCardApiToken',
  inheritAttrs: false,
  components: { FormTextarea, PrimaryCheckbox, ActionButton, CancelButton },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    token:  {
      type: String,
      default: null
    },
  },
  data: () => ({
    isCopied: false,
  }),
  methods: {
    copyToken() {
      copyInStash(this.token)
      this.$notify({ type: 'success-bg', text: 'Copied to the clipboard' })
      this.isCopied = true
    },
    closeDialog() {
      if (this.isCopied) {
        this.$emit('closeDialog')
      } else {
        this.$notify({ type: 'error-bg', text: 'Please copy the token before closing this window!' })
      }
    },
  },
  watch: {
    show: {
      handler(show) {
        if (!show) {
          this.isCopied = false
        }
      }
    },
  },
}
</script>