<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">API Access Tokens</div>
      </portal>

      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" @click="openDialogAddAccessToken">
        Add Access Token
      </ActionOutlinedButton>

      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />

      <v-spacer />

      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>

        <template #item.last_use="{ item }">
          <span class="font-normal text-no-wrap">
            {{ !!item['last_use'] ? formatDate(item['last_use']) : '-//-' }}
          </span>
        </template>

        <template #item.date="{ item }">
          <span class="font-normal text-no-wrap">
            {{ formatDate(item['created_at']) }}
          </span>
        </template>

        <template #item.actions="{ item }">
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeleteTokenDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>

    <DialogCreateApiToken
      v-model="createDialog"
      @createSuccess="loadAccessTokens"
    />

    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :maxWidth="560"
      withAuthConform
      title="Delete API Access Token"
      subTitle=""
      message="Are you sure you want to delete API Access Token?"
      @actionSuccess="goToDeleteToken"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SelectOnPage from '@/components/select/SelectOnPage'
import TablePagination from '@/components/pagination/TablePagination'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogCreateApiToken from '@/components/dialog/DialogCreateApiToken'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { getOnPage, parsePaginationData, formatCallLogDate } from '@/helper/app-helper'
import { getApiTokens, deleteApiToken } from '@/api/api-tokens-routes'

export default {
  name: 'ManageApiTokens',
  components: { ActionOutlinedButton, RefreshButton, SelectOnPage, TablePagination, TooltipAction, DialogCreateApiToken, DialogConfirm },
  data: () => ({
    loading: false,
    dialog: false,
    createDialog: false,
    confirmDialog: false,
    loadingAction: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headers: [
      { text: 'Name', value: 'name', sortId: 'byName' },
      { text: 'Last Use', value: 'last_use', sortId: 'byLastUse' },
      { text: 'Created At', value: 'date', sortId: 'byCreatedAt' },
      { text: 'Actions', value: 'actions', sortable: false, width: 80, align: 'right' },
    ],
    serverSorting: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    formatDate(date) {
      return formatCallLogDate(date)
    },
    async loadAccessTokens() {
      this.loading = true

      const { success, payload, message } = await getApiTokens({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadAccessTokens()
      }
      this.$vuetify.goTo(0)
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadAccessTokens()
    },
    openDialogAddAccessToken() {
      this.createDialog = true
    },
    openDeleteTokenDialog(item) {
      this.tokenForDelete = item
      this.confirmDialog = true
    },
    async goToDeleteToken(formData) {
      this.loadingAction = true

      const { success, payload, message } = await deleteApiToken({
        slug: this.tokenForDelete.slug,
        formData
      })

      if (success) {
        this.confirmDialog = false
        this.$notify({ type: 'success-bg', text: message })
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadAccessTokens()
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadAccessTokens()
      },
    },
  },
}
</script>