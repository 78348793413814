<template>
  <v-card class="cardBg" outlined>
    <v-form ref="form" v-model="validateForm">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column align-start align-sm-end justify-center pr-0 pr-sm-11 pb-16"
          cols="12" sm="4"
        >
          <span class="font-large-title text-uppercase grayLighten3--text">Add Token</span>
        </v-col>
        <v-col cols="12" sm="8"></v-col>

        <v-col
          class="d-flex flex-column align-start align-sm-end justify-center pr-11"
          cols="12" sm="4"
        >
          <span class="font-normal nameField--text">Name</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="apiTokenName"
          ></portal-target>
        </v-col>
        <v-col cols="12" sm="8" class="mt-7 mt-sm-0">
          <FormField
            v-model="formData.name"
            :rules="rules"
          >
            <template #message="{ key, message }">
              <portal to="apiTokenName" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>
      </v-row>

      <v-card-actions class="justify-end pr-0 pt-0 mt-16">
        <ActionButton class="mr-5 mr-sm-10" @click="submitForm">Add</ActionButton>

        <CancelButton @click="closeDialog">Cancel</CancelButton>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

export default {
  name: 'DialogCardAccessApiTokenName',
  inheritAttrs: false,
  components: { FormField, ActionButton, CancelButton },
  props: {
    show:  {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    formData: {
      name: null,
    },
    validateForm: true,
    rules: [],
  }),
  methods: {
    async submitForm() {
      await this.validationForm()

      if (this.$refs.form.validate()) {
        this.$emit('goToConfirmStep', this.formData.name)
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    clearForm() {
      setTimeout(() => {
        this.rules = []
        this.formData.name = null
      })
    },
  },
  watch: {
    show: {
      handler(show) {
        if (!show) {
          this.clearForm()
        }
      }
    },
  },
}
</script>